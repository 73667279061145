exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jakarta-ayana-midplaza-jakarta-support-jsx": () => import("./../../../src/pages/jakarta/ayana-midplaza-jakarta/support.jsx" /* webpackChunkName: "component---src-pages-jakarta-ayana-midplaza-jakarta-support-jsx" */),
  "component---src-pages-jakarta-ayana-midplaza-jakarta-thank-you-jsx": () => import("./../../../src/pages/jakarta/ayana-midplaza-jakarta/thank-you.jsx" /* webpackChunkName: "component---src-pages-jakarta-ayana-midplaza-jakarta-thank-you-jsx" */),
  "component---src-pages-labuan-bajo-komodo-faq-jsx": () => import("./../../../src/pages/labuan-bajo/komodo/faq.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-komodo-faq-jsx" */),
  "component---src-pages-labuan-bajo-komodo-locate-us-jsx": () => import("./../../../src/pages/labuan-bajo/komodo/locate-us.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-komodo-locate-us-jsx" */),
  "component---src-pages-labuan-bajo-komodo-support-jsx": () => import("./../../../src/pages/labuan-bajo/komodo/support.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-komodo-support-jsx" */),
  "component---src-pages-labuan-bajo-komodo-thank-you-jsx": () => import("./../../../src/pages/labuan-bajo/komodo/thank-you.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-komodo-thank-you-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-destination-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/destination.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-destination-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-faq-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/faq.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-faq-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-itinerary-luxury-charter-cruises-around-komodo-national-park-with-ayana-lako-dia-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/itinerary/luxury-charter-cruises-around-komodo-national-park-with-ayana-lako-dia.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-itinerary-luxury-charter-cruises-around-komodo-national-park-with-ayana-lako-dia-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-locate-us-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/locate-us.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-locate-us-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-support-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/support.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-support-jsx" */),
  "component---src-pages-labuan-bajo-lakodia-thank-you-jsx": () => import("./../../../src/pages/labuan-bajo/lakodia/thank-you.jsx" /* webpackChunkName: "component---src-pages-labuan-bajo-lakodia-thank-you-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-experience-jsx": () => import("./../../../src/templates/experience.jsx" /* webpackChunkName: "component---src-templates-experience-jsx" */),
  "component---src-templates-offer-jsx": () => import("./../../../src/templates/offer.jsx" /* webpackChunkName: "component---src-templates-offer-jsx" */),
  "component---src-templates-room-jsx": () => import("./../../../src/templates/room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */),
  "component---src-templates-wedding-package-jsx": () => import("./../../../src/templates/wedding_package.jsx" /* webpackChunkName: "component---src-templates-wedding-package-jsx" */)
}

