module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 640px)","sm":"(min-width: 768px)","md":"(min-width: 1024px)","lg":"(min-width: 1280px)","xl":"(min-width: 1536px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/favicon-ayana.png","include_favicon":false,"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3e6f709df77f230f516bbd00a8857ca9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","zh","ja","ko"],"defaultLanguage":"en","siteUrl":"https://www.ayana.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":","pluralSeparator":"_"},"pages":[{"matchPath":"/preview","languages":["en"]},{"matchPath":"/:lang?/jakarta/ayana-midplaza-jakarta/(support|thank-you)/:rest?","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/:lang?/404/","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/:lang?/labuan-bajo/komodo/(locate-us|faq|support|thank-you)/:rest?","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/:lang?/labuan-bajo/lakodia/(locate-us|faq|support|destination|thank-you)/:rest?","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/labuan-bajo/lakodia/itinerary/luxury-charter-cruises-around-komodo-national-park-with-ayana-lako-dia/","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/","getLanguageFromPath":false,"languages":["en","zh","ja","ko"]},{"matchPath":"/:lang?/(media-center|jakarta|bali|labuan-bajo)/:rest*","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/@code-mancers/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NNZZS5","includeInDevelopment":false,"enableWebVitalsTracking":true,"fireGtmOnFirstPageLoad":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
